import Filters from "@/components/Filters"
export default {
  data() {
    return {
      extraFilters: []
    }
  },
  components: {
    Filters
  },
  computed: {
    extraFiltersInput: function () {
      if (this.extraFilters) {
        return this.extraFilters.map(filter => {
          return {
            name: filter.name,
            value: filter.value ? filter.value.toString() : "",
            values: filter.values || []
          }
        })
      }
      return []
    }
  }
}
