<template>
  <div class="position-relative h-100">
    <portal to="header-bottom">
      <filters v-show="!$route.params.id" @updated="updatedFilter" />
    </portal>
    <div v-if="!loading && venuesAndPremiums.data.length">
      <venues
        v-if="venuesAndPremiums.data"
        :venues="venuesAndPremiums.data"
        :advertiser-day="advertiserDay"
      />
      <div
        v-if="hasMore"
        class="h-100 d-flex align-items-center justify-content-center m-5"
      >
        <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
      </div>
    </div>
    <div
      v-else-if="!loading"
      class="h-100 d-flex align-items-center justify-content-center px-2"
    >
      <b-alert variant="warning" class="text-center" show>
        Aucun établissement ne correspond à votre recherche
      </b-alert>
    </div>
    <fit-height-loader
      v-else-if="!$route.params.id"
      class="position-absolute"
      show
      style="z-index: 100; background-color: rgba(255, 255, 255, 0.5)"
    />
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
  </div>
</template>
<script>
import detectIt from "detect-it"
import VENUES_AND_PREMIUMS from "@/graphql/VenuesAndPremiums.gql"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
import { mapState } from "vuex"
import { setTimeout } from "timers"
import venueList from "@/mixins/VenueList"
import filterable from "@/mixins/filterable"
import FitHeightLoader from "@/components/FitHeightLoader"

export default {
  components: {
    FitHeightLoader
  },
  mixins: [venueList, filterable],
  data() {
    return {
      perPage: 100,
      hasMore: true,
      loading: true,
      extraFilters: []
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius,
      appReady: state => state.appReady,
      page: state => state.page
    })
  },
  watch: {
    coordinate: {
      handler: function () {
        this.reset()
      },
      deep: true
    },
    radius: function () {
      this.reset()
    }
  },
  created() {
    this.$apollo.queries.venuesAndPremiums.skip = true
  },
  mounted() {
    let elt = document.getElementById("content")
    elt.addEventListener(
      "scroll",
      this.onScroll,
      detectIt.passiveEvents ? { passive: true } : false
    )
    if (this.appReady) {
      setTimeout(() => {
        this.reset()
      })
      //
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    venuesAndPremiums: {
      query: VENUES_AND_PREMIUMS,
      skip: true
      // Additional options here
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  beforeDestroy() {
    let elt = document.getElementById("content")
    elt.removeEventListener("scroll", this.onScroll)
    this.$apollo.queries.venuesAndPremiums.skip = true
  },
  methods: {
    more() {
      this.$store.commit("nextPage")
      this.$apollo.queries.venuesAndPremiums.fetchMore({
        // New variables
        variables: this.variables(),
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newVenues = fetchMoreResult.venuesAndPremiums.data
          const hasMore =
            this.venuesAndPremiums.data.length <
            fetchMoreResult.venuesAndPremiums.total
          this.hasMore = hasMore

          return {
            venuesAndPremiums: {
              __typename: previousResult.venuesAndPremiums.__typename,
              data: [...previousResult.venuesAndPremiums.data, ...newVenues],
              hasMore,
              total: fetchMoreResult.venuesAndPremiums.total
            }
          }
        }
      })
    },
    onScroll() {
      let elt = document.getElementById("content")
      if (
        !this.$apollo.loading &&
        elt.scrollTop + elt.offsetHeight > elt.scrollHeight - 2000 &&
        this.hasMore
      ) {
        if (elt.scrollTop > 0) {
          this.more()
        }
      }
    },
    reset() {
      let elt = document.getElementById("content")
      elt.scrollTop = 0
      this.$store.commit("firstPage")
      this.hasMore = true
      this.loading = true
      this.$apollo.queries.venuesAndPremiums.setVariables(this.variables())

      this.$nextTick(() => {
        this.$apollo.queries.venuesAndPremiums.skip = false
        this.$apollo.queries.venuesAndPremiums.refetch().then(() => {
          this.loading = false
          this.hasMore = this.venuesAndPremiums.data.length >= this.perPage
        })
      })
    },

    variables() {
      return {
        perPage: this.perPage,
        page: this.page,
        coordinate: {
          lat: this.coordinate.lat,
          lng: this.coordinate.lng
        },
        radius: this.radius,
        informations: ["short_description"],
        excludeBookmarked: false,
        extraFilters: this.extraFiltersInput
      }
    },
    swipe() {
      this.$router.push({ name: "list" })
    },
    updatedFilter(filters) {
      this.extraFilters = filters
      this.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
.venue-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  background: white;
}
.swipe-enter-active,
.swipe-leave-active {
  transition: transform 0.2s;
}
.swipe-enter, .swipe-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
}
</style>
