<template>
  <div class="d-inline-block">
    <b-btn
      :variant="variant"
      class="rounded-pill text-uppercase"
      size="sm"
      @click="onClick"
    >
      {{ filter.text }}
    </b-btn>
    <b-modal
      v-if="filter.type === 'Array'"
      ref="refValuesModal"
      :title="filter.text"
      class="values-modal filter-modal"
      size="lg"
    >
      <b-row>
        <b-col v-for="(availableValues, i) in options" :key="i">
          <b-form-group>
            <b-form-checkbox-group
              v-model="filter.values"
              stacked
              name="values"
              :options="availableValues"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div slot="modal-footer" class="w-100 d-flex justify-content-end">
        <b-btn size="sm" variant="secondary" class="mr-2" @click="close">
          Annuler
        </b-btn>
        <b-btn size="sm" variant="primary" @click="handleOk"> Valider </b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { chunk } from "@/utils"
export default {
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    variant: function () {
      if (this.filter.type === "Boolean") {
        return this.filter.value ? "brown" : "outline-brown"
      }
      return this.filter.values.length ? "brown" : "outline-brown"
    },
    options: function () {
      if (this.filter.type === "Array") {
        let size = 2
        if (window.innerWidth < 576) {
          size = 2
        } else if (window.innerWidth < 768) {
          size = 2
        } else if (window.innerWidth < 992) {
          size = 3
        } else if (window.innerWidth < 1200) {
          size = 4
        } else {
          size = 4
        }
        return chunk(
          this.filter.availableValues,
          Math.ceil(this.filter.availableValues.length / size)
        )
      }
      return null
    }
  },
  created() {
    this.$set(this.filter, "values", [])
    this.$set(this.filter, "value", null)
  },
  methods: {
    onClick() {
      if (this.filter.type === "Boolean") {
        this.filter.value = !this.filter.value
        this.$emit("updated")
      } else {
        this.$refs.refValuesModal.show()
      }
    },
    close() {
      this.$refs.refValuesModal.hide()
    },
    handleOk() {
      this.$emit("updated")
      this.$refs.refValuesModal.hide()
    }
  }
}
</script>

<style lang="scss">
.values-modal {
  .custom-control label {
    span {
      white-space: normal;
    }
  }
}
</style>
