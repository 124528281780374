<template>
  <div class="filters-component">
    <portal v-if="allFilters" to="splash">
      <b-form
        class="h-100 d-flex flex-column layout filters-component"
        @submit.prevent="
          updatedFilter()
          allFilters = false
        "
        @keyup.esc="allFilters = false"
      >
        <layout-header>
          <template #nav>
            <b-link class="text-white" @click.prevent="allFilters = false">
              <font-awesome-icon icon="chevron-left" size="2x" />
            </b-link>
            <h4
              class="flex-fill text-center text-white mb-0 text-uppercase font-weight-demi"
            >
              Filtres
            </h4>
            <b-link
              class="flat text-uppercase text-white undo-filter"
              @click="resetFilters"
            >
              Réinitialiser
            </b-link>
          </template>
        </layout-header>
        <main class="flex-fill pt-4">
          <div class="content">
            <div class="p-2 shadow-lg border">
              <b-container>
                <b-form-group
                  v-for="(filter, i) in extraFilters"
                  :key="i"
                  :label="filter.text"
                >
                  <b-row v-if="filter.type === 'Array'" no-gutters>
                    <b-col
                      v-for="(availableValues, j) in chunkedValues(
                        filter.availableValues
                      )"
                      :key="j"
                      :cols="cols"
                    >
                      <b-form-checkbox-group
                        v-model="filter.values"
                        stacked
                        name="values"
                        :options="availableValues"
                      />
                    </b-col>
                  </b-row>

                  <toggle-button
                    v-else
                    v-model="filter.value"
                    :sync="true"
                    :value="filter.value"
                    :color="{ checked: '#28a745', unchecked: '#dc3545' }"
                    :labels="{ checked: 'Oui', unchecked: 'Non' }"
                  />
                </b-form-group>
              </b-container>
            </div>
          </div>
        </main>
        <footer class="px-5 py-3">
          <b-btn
            size="sm"
            type="submit"
            class="rounded-pill text-uppercase"
            block
            variant="primary"
          >
            Valider
          </b-btn>
        </footer>
      </b-form>
    </portal>

    <div v-show="!allFilters" class="text-nowrap filters-wrapper border-top">
      <div v-if="!$apollo.loading" class="py-2">
        <b-btn
          size="sm"
          :variant="filtersCount > 0 ? 'brown' : 'outline-brown'"
          class="ml-2 rounded"
          @click="allFilters = !allFilters"
        >
          <font-awesome-icon icon="filter" />Filtres
        </b-btn>
        <custom-filter
          v-for="(filter, i) in extraFilters"
          :key="i"
          :filter="filter"
          class="ml-2 filter text-uppercase"
          @updated="updatedFilter()"
        />
      </div>
      <fit-height-loader v-else size="1x" class="py-2" />
    </div>
  </div>
</template>

<script>
import CustomFilter from "@/components/Filter"
import EXTRA_FILTERS from "@/graphql/ExtraFilters.gql"
import FitHeightLoader from "@/components/FitHeightLoader"
import LayoutHeader from "@/components/LayoutHeader.vue"
import { chunk } from "@/utils"
export default {
  components: {
    CustomFilter,
    FitHeightLoader,
    LayoutHeader
  },
  data() {
    return {
      allFilters: false
    }
  },
  computed: {
    filters: function () {
      return this.extraFilters.slice(0)
    },
    filtersCount: function () {
      return this.filters.filter(f => f.value).length
    },
    cols: function () {
      let cols = 6
      if (window.innerWidth < 576) {
        cols = 6
      } else if (window.innerWidth < 768) {
        cols = 6
      } else if (window.innerWidth < 992) {
        cols = 4
      } else if (window.innerWidth < 1200) {
        cols = 3
      } else {
        cols = 3
      }
      return cols
    }
  },
  apollo: {
    extraFilters: {
      query: EXTRA_FILTERS
    }
  },
  methods: {
    updatedFilter() {
      this.$emit("updated", this.extraFilters)
    },
    chunkedValues(options) {
      let size = 12 / this.cols
      return chunk(options, Math.ceil(options.length / size))
    },
    resetFilters() {
      this.extraFilters.forEach(filter => {
        filter.value = false
        filter.values = []
      })
    }
  }
}
</script>

<style lang="scss">
.filters-wrapper {
  overflow-x: hidden;
  > div {
    overflow-x: auto;
  }
}
.undo-filter {
  font-size: 12px;
}
</style>
